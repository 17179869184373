<template>
  <button class="logout-btn">
    <svg-icon name="logout"></svg-icon>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
.logout-btn
  border: none
  background-color: transparent
  padding: 0
  cursor: pointer
  transition: var(--transition-speed)

.logout-btn:hover
  opacity: 0.8

.logout-btn[disabled]
  cursor: default

.logout-btn[disabled] .icon-logout path
  opacity: 0.5

.logout-btn[disabled] .icon-logout path:first-child
  stroke: var(--primary-color-dark-grey-1)

.logout-btn[disabled] .icon-logout path:last-child
  fill: var(--primary-color-dark-grey-1)
</style>
