<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <div class="header__left">
          <div class="header__logo">
            <AppLogo @click.native.prevent="logoClickHandler" />
          </div>
        </div>
        <div v-if="isAuthUser" class="header__right">
          <UserProfile />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLogo from '@/components/Logo/AppLogo.vue';
import UserProfile from '@/components/UserProfile/UserProfile.vue';

export default {
  components: {
    AppLogo,
    UserProfile,
  },
  computed: {
    ...mapGetters(['isAuthUser']),
    isClickableLogo() {
      return this.$route.name !== 'dashboard' && this.$route.name !== 'auth';
    },
  },
  methods: {
    logoClickHandler() {
      if (this.isClickableLogo) {
        this.$emit('go-to-dashboard');
      }

      return;
    },
  },
};
</script>

<style lang="sass" scoped>
.header
  padding: 18px 0 14px 0
  border-bottom: 1px solid var(--primary-color-light-grey-2)

  @media screen and (max-width: 1199px)
    padding: 15px 0


.header__inner
  display: flex
  align-items: center
  justify-content: space-between
</style>
