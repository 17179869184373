<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__left">
          <a href="#" class="footer__link">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6.50043H13.9963C13.8845 3.26644 11.3202 0.597185 8.18375 0.503435C7.38032 0.476129 6.57968 0.61184 5.83013 0.90238C5.08058 1.19292 4.39763 1.63227 3.8225 2.19394C3.24353 2.7514 2.78354 3.42041 2.4703 4.16058C2.15707 4.90075 1.99708 5.69672 2 6.50043C1.17275 6.50043 0.5 7.17318 0.5 8.00043V11.0004C0.5 11.8277 1.17275 12.5004 2 12.5004H2.75C2.94891 12.5004 3.13968 12.4214 3.28033 12.2808C3.42098 12.1401 3.5 11.9493 3.5 11.7504V6.50043C3.5 5.27269 3.98525 4.12594 4.8665 3.27018C5.29839 2.84955 5.81077 2.52046 6.37293 2.30266C6.93509 2.08486 7.53545 1.98283 8.138 2.00269C10.544 2.07469 12.5 4.19118 12.5 6.72019V12.5004C12.5 13.3277 11.8273 14.0004 11 14.0004H9.5C9.5 13.8015 9.42098 13.6108 9.28033 13.4701C9.13968 13.3295 8.94891 13.2504 8.75 13.2504H7.25C7.05109 13.2504 6.86032 13.3295 6.71967 13.4701C6.57902 13.6108 6.5 13.8015 6.5 14.0004V14.7504C6.5 14.9493 6.57902 15.1401 6.71967 15.2808C6.86032 15.4214 7.05109 15.5004 7.25 15.5004H11C12.6545 15.5004 14 14.1549 14 12.5004C14.8273 12.5004 15.5 11.8277 15.5 11.0004V8.00043C15.5 7.17318 14.8273 6.50043 14 6.50043Z"
                fill="#283B59"
              />
            </svg>
            <span>{{ $t('master.support') }}</span>
          </a>
        </div>
        <div class="footer__copyright">Copyright © {{ currentYear }}</div>
        <div class="footer__right">
          <span>Powered by</span>
          <a href="https://bazucompany.com/" target="_blank">
            <CompanyLogo />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import CompanyLogo from '@/components/Logo/CompanyLogo.vue';

export default {
  components: {
    CompanyLogo,
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="sass" scoped>
.footer
  background-color: var(--primary-color-light-grey-1)
  border-top: 1px solid var(--primary-color-light-grey-3)
  padding: 20px 0
  margin-top: auto

.footer__inner
  display: flex
  align-items: center
  justify-content: space-between

.footer__link,
.footer__right
  display: flex
  align-items: center

.footer__link,
.footer__copyright,
.footer__right
  color: var(--primary-color-dark-blue)
  font-size: 16px
  line-height: 19px

.footer__left
  span
    margin-left: 8px
    font-weight: bold

.footer__right
  span
    margin-right: 16px
</style>
