<template>
  <main class="main">
    <Header @go-to-dashboard="openModalGoToDashboard" />
    <Content @go-to-dashboard="openModalGoToDashboard" />
    <Footer />

    <Popup :dialog.sync="modalRotateDevice" is-persistent>
      <div class="rotate-device">
        <svg-icon name="rotate-device" class="popup__icon"></svg-icon>
        <Heading level="4">
          {{ $t('popup.rotate_device') }}
        </Heading>
        <div class="popup__subtitle">
          {{ $t('popup.rotate_device_info') }}
        </div>
      </div>
    </Popup>

    <Popup :dialog.sync="modalGoToDashboard">
      <Heading level="4" class="popup__title">
        {{ $t('popup.go_to_dashboard') }}
      </Heading>
      <div class="popup__subtitle">
        {{ $t('popup.shure_go_to_back') }}
      </div>
      <div class="popup__buttons-box">
        <ButtonBase
          color="white"
          size="md-bg"
          class="popup__btn"
          @click.native="closeModalGoToDashboard"
        >
          <span class="new-ticket__btn-titles">
            {{ $t('new_ticket_info.cancel') }}
          </span>
        </ButtonBase>
        <ButtonBase
          color="blue"
          size="md-bg"
          class="popup__btn"
          @click.native="goToDashboard"
        >
          {{ $t('popup.confirm') }}
        </ButtonBase>
      </div>
    </Popup>

    <Notification
      v-if="notification"
      :type="notification.type"
      :title="notification.title"
      :text="notification.text"
    />
  </main>
</template>

<script>
import { removeFromLS, isLSHasItem } from '@/library/helpers';

import Header from '@/components/Layout/Header/Header.vue';
import Content from '@/components/Layout/Content/Content.vue';
import Footer from '@/components/Layout/Footer/Footer.vue';

import Popup from '@/elements/Popup/Popup.vue';
import Notification from '@/elements/Notification/Notification.vue';
import Heading from '@/elements/Heading/Heading.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';

export default {
  components: {
    Header,
    Content,
    Footer,
    Popup,
    Notification,
    Heading,
    ButtonBase,
  },
  data() {
    return {
      modalRotateDevice: false,
      modalGoToDashboard: false,
    };
  },
  computed: {
    notification() {
      return this.$store.getters.getNotification;
    },
  },
  mounted() {
    if (document.documentElement.clientWidth <= 990) {
      this.openModalRotateDevice();
    }
    let resizeTimeout;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if (document.documentElement.clientWidth <= 990) {
          this.openModalRotateDevice();
        } else {
          this.closeModalRotateDevice();
        }
      }, 100);
    });
  },
  methods: {
    openModalRotateDevice() {
      this.modalRotateDevice = true;
    },
    closeModalRotateDevice() {
      this.modalRotateDevice = false;
    },
    openModalGoToDashboard() {
      this.modalGoToDashboard = true;
    },
    closeModalGoToDashboard() {
      this.modalGoToDashboard = false;
    },
    goToDashboard() {
      this.closeModalGoToDashboard();

      if (isLSHasItem('new-ticket')) {
        removeFromLS('new-ticket');
      }

      this.$router.push('/');
    },
  },
};
</script>
<style lang="sass" scoped>
.main
  min-height: 100vh
  display: flex
  flex-direction: column
</style>
