<template>
  <img src="@/assets/images/logo/company-logo.svg" alt="BAZU" />
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
img
  display: block
</style>
