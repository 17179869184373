<template>
  <div class="content">
    <div class="container">
      <Breadcrumbs
        v-if="isShowBreadcrumbs"
        @go-to-dashboard="$emit('go-to-dashboard')"
      />
      <router-view />
    </div>
  </div>
</template>
<script>
import Breadcrumbs from '@/elements/Breadcrumbs/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
  },
  computed: {
    isShowBreadcrumbs() {
      return !!this.$route.meta.breadcrumb;
    },
  },
};
</script>

<style lang="sass" scoped>
.content
  background-color: var(--primary-color-light-grey-1)
  flex-grow: 1
</style>
