<template>
  <div :class="cssClasses" class="notification">
    <div class="notification__title">
      <svg
        v-if="type === 'danger'"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="14" cy="14" r="12.7" stroke="#FF0000" stroke-width="1.4" />
        <rect
          x="8"
          y="18.6328"
          width="15.0371"
          height="1.8"
          rx="0.9"
          transform="rotate(-45 8 18.6328)"
          fill="#FF0000"
        />
        <rect
          x="9.36719"
          y="8"
          width="15.0371"
          height="1.8"
          rx="0.9"
          transform="rotate(45 9.36719 8)"
          fill="#FF0000"
        />
      </svg>
      <svg-icon v-else :name="nameOfIcon"></svg-icon>
      {{ title }}
    </div>
    <div class="notification__text">{{ text }}</div>
    <button class="notification__close" @click="close">
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="1.71391"
          height="20.567"
          rx="0.856957"
          transform="matrix(0.695935 0.718104 -0.695935 0.718104 15.313 0)"
          fill="#283B59"
        />
        <rect
          width="1.71391"
          height="20.567"
          rx="0.856957"
          transform="matrix(-0.695935 0.718104 0.695935 0.718104 2 0)"
          fill="#283B59"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    cssClasses() {
      return {
        'notification--success': this.type === 'success',
        'notification--warning': this.type === 'warning',
        'notification--danger': this.type === 'danger',
      };
    },

    nameOfIcon() {
      return `icon-${this.type}`;
    },
  },
  methods: {
    close() {
      this.$store.commit('clearNotification');
    },
  },
};
</script>

<style lang="sass" scoped>
.notification
  display: flex
  flex-direction: column
  padding: 25px
  position: fixed
  min-width: 376px
  bottom: 90px
  right: 32px
  background-color: var(--primary-color-white)
  border-radius: var(--primary-border-radius)
  z-index: 9

.notification--success
  border: 1px solid var(--primary-color-success)

.notification--warning
  border: 1px solid #EAB52A

.notification--danger
  border: 1px solid var(--primary-color-error)

.notification__close
  position: absolute
  right: 12px
  top: 12px

.notification__title
  display: flex
  align-items: center
  gap: 15px
  font-size: 24px
  font-weight: bold
  margin: 0 0 12px

.notification__text
  font-size: 18px
  line-height: 24px
  color: var(--primary-color-dark-blue)

.icon-icon-close
  width: 16px
  height: 16px
</style>
