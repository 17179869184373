<template>
  <div class="breadcrumbs">
    <a class="breadcrumbs__item-back" @click="goToDashboard">
      <svg-icon name="back-arrow"></svg-icon>
    </a>
    <router-link
      v-for="(item, idx) in breadcrumbs"
      :key="item.name"
      :to="{ name: `${item.name}` }"
      class="breadcrumbs__item"
      :disabled="idx === breadcrumbs.length - 1"
    >
      {{ item.title }}
    </router-link>
  </div>
</template>

<script>
import {
  setToLS,
  getFromLS,
  removeFromLS,
  isLSHasItem,
} from '@/library/helpers';

export default {
  data() {
    return {
      breadcrumbs: isLSHasItem('breadcrumbs') ? getFromLS('breadcrumbs') : [],
    };
  },
  watch: {
    $route(to, from) {
      this.setBreadcrumbs();
    },
  },
  created() {
    const isCurrentRoute = !!this.breadcrumbs.find(
      (item) => item.name === this.$route.name
    );

    if (!isCurrentRoute) {
      this.breadcrumbs.length = 0;
    }
    this.setBreadcrumbs();
  },

  beforeDestroy() {
    this.clearBreadcrumbs();
  },

  methods: {
    setBreadcrumbs() {
      const currentBreadcrumb = {
        name: this.$route.name,
        title: this.$route.meta.breadcrumb.name,
      };

      if (this.breadcrumbs.find((el) => el.name === currentBreadcrumb.name)) {
        let idx = this.breadcrumbs.findIndex(
          (el) => el.name === currentBreadcrumb.name
        );

        this.breadcrumbs.splice(idx + 1);
      } else {
        this.breadcrumbs.push(currentBreadcrumb);
      }

      setToLS('breadcrumbs', this.breadcrumbs);
    },
    clearBreadcrumbs() {
      removeFromLS('breadcrumbs');
    },
    goToDashboard() {
      this.$emit('go-to-dashboard');
    },
  },
};
</script>

<style lang="sass">
.breadcrumbs
  padding: 40px 0 0 0!important
  display: flex
  align-items: center
  @media screen and (max-width: 1199px)
    padding: 32px 0 0 0!important

.breadcrumbs__item
    border-left: 1px solid var( --primary-color-dark-grey-3)
    border-top: 1px solid var( --primary-color-dark-grey-3)
    border-bottom: 1px solid var( --primary-color-dark-grey-3)
    border-radius: var(--primary-border-radius)
    position: relative
    justify-content: center
    margin-right: 18px
    font-weight: 500
    font-size: 18px
    line-height: 18px
    color: var(--primary-color-dark-blue)
    padding: 15px

.breadcrumbs__item:before,
.breadcrumbs__item:after
    background-color: var( --primary-color-dark-grey-3)
    position: absolute
    content: ""
    width: 1px
    height: 25px
    right: -4px

.breadcrumbs__item:before
  bottom: 0
  transform: rotate(21deg)

.breadcrumbs__item:after
  top: 0px
  transform: rotate(-21deg)

.breadcrumbs__item[disabled]
  border-left: 1px solid rgba(40, 59, 89, 0.3)
  border-top: 1px solid rgba(40, 59, 89, 0.3)
  border-bottom: 1px solid rgba(40, 59, 89, 0.3)
  color: var(--primary-color-dark-grey-1)

.breadcrumbs__item[disabled]:before,
.breadcrumbs__item[disabled]:after
  background-color: rgba(40, 59, 89, 0.3)

.breadcrumbs__item-back
  display: flex
  align-items: center
  justify-content: center
  width: 50px
  height: 50px
  border: 1px solid var( --primary-color-dark-grey-3)
  border-radius: var(--primary-border-radius)
  margin-right: 12px
  cursor: pointer

.icon-back-arrow
  width: 23px
  height: 14px
</style>
